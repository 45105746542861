/* TODO: Remove when react-scripts 5.0.0 and craco-antd are compatible */

/*

Reason and Solution: https://stackoverflow.com/a/70452191

The error is as a result of react-error-overlay (which many people would never have heard of because it is a dependency of react-scripts). This package's dependencies were update to support webpack v5, which unfortunately is not compatible with react-scripts v4.
* Unwanted react-error-overlay version: 6.0.10
* should be: 6.0.9

### Method 0
Upgrade react-scripts to v5.0.0

Problem:
* craco-antd not yet supporting react-scripts v5.0.0. See: https://github.com/DocSpring/craco-antd/issues/73

### Method 1
 Pin react-error-overlay to version 6.0.9

Add this to your package.json
```
"resolutions": {
  "//": "See https://github.com/facebook/create-react-app/issues/11773",
  "react-error-overlay": "6.0.9"
}
```

**Yarn User**

Yarn: will take the resolutions field into consideration out of the box.

In Dockerfile-frontend-development, replace `RUN npm install` with 

```
RUN yarn install --ignore-engines
```

Then `docker-compose up --build` to rebuild the container

**NPM User**

`npm-force-resolutions`: https://stackoverflow.com/a/70791920. 

However, `npm-force-resolutions` not working since in 6.0.10, some dependencies like `react-dev-utils` will install this version even if you pin the version of react-error-overlay to 6.0.9

Solution -> use `overrides` instead of `resolutions` in package.json: https://stackoverflow.com/a/70979114. Problem, required npm > v8.3 to work (our current docker node:14.6-slim has npm = v6)

### Method 2

webpack plugin in craco config: https://stackoverflow.com/a/70452191

Somehow not working

### Method 3

css hack. Which we are doing. See ./client/src/styles/react-error-overlay-fix

```
iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
    display: none;
}
```

*/

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
    display: none;
}

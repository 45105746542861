@import (inline) "colors.less";

//export colors to js
:export {
    transparent: @transparent;
    aliceblue: @aliceblue;
    ceil: @ceil;

    green1: @green1;
    green2: @green2;
    green3: @green3;
    yellow1: @yellow1;
    yellow2: @yellow2;
    yellow3: @yellow3;
    red1: @red1;
    red2: @red2;
    red3: @red3;
    grey1: @grey1;
    grey2: @grey2;
    grey3: @grey3;
    blue1: @blue1;
    blue2: @blue2;
    white1: @white1;
    white2: @white2;
    black1: @black1;
}

// html
html {
    scroll-behavior: smooth;
    background: #f5f5f5;
    margin: 0px 20px;
}

// scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #efeff5;
}

::-webkit-scrollbar-thumb {
    background: @grey1;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: @grey2;
}

// sider
.ant-layout-sider {
    background: transparent !important;
    padding-top: 13px;
    padding-right: 20px;
}

// menu
.ant-menu-light {
    background: transparent !important;
    border: 0 !important;
    color: @grey3;
}

.ant-menu .ant-menu-sub {
    background: transparent !important;
}

.ant-menu-submenu-title {
    color: @grey3 !important;
}

.ant-menu-item-selected {
    background-color: @blue2!important;
    color: @white1 !important;
}

// breadcrumb

.ant-breadcrumb a {
    color: @grey3 !important;
}

// card

.ant-card {
    border-radius: 12px;
    border: 0 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 5%), 0 1px 6px -1px rgba(0, 0, 0, 10%), 0 1px 4px 0 rgba(0, 0, 0, 5%) !important;
}

.ant-card-head {
    padding-top: 15px !important;
    border: none !important;
}

.ant-card-actions {
    height: 0 !important;
    border: 0 !important;
}

.ant-card-head-title {
    font-weight: 500;
}

// table
.ant-table-column-title {
    font-weight: 600;
}

.ant-table-cell {
    a:hover {
        font-weight: 600;
        color: @black1;
    }
}

// pagination
.ant-pagination-item-active,
.ant-pagination-item-active:focus {
    border-color: @grey1 !important;
}

.ant-pagination-item-active a {
    color: @black1 !important;
    font-weight: 400;
}

.ant-pagination-item:hover a,
.ant-pagination-item:focus a {
    color: @black1 !important;
    background: @grey1 !important;
    border-radius: 4px;
}

// input group
.ant-input-group.ant-input-group-compact {
    display: flex !important;
}

// button
.ant-btn {
    box-shadow: unset;
    font-weight: 600;
}

// select
.ant-select-selector:hover {
    color: @blue2 !important;
}

// form
.ant-form-item-required::before {
    display: none !important;
}

// result
span.anticon-exclamation-circle > svg {
    fill: @blue2 !important;
}

// modal

.ant-modal-footer {
    margin-top: 24px !important;
}

// .ant-select-focused .ant-select-selector,
// .ant-select-selector:focus,
// .ant-select-selector:active,
// .ant-select-open .ant-select-selector {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
//     color: @blue2 !important;
// }

// leaflet
.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    background: transparent !important;
    border: none !important;
    margin-left: -18px !important;
    margin-top: 0 !important;
}

.leaflet-tile-pane {
    -webkit-filter: grayscale(80%);
    filter: grayscale(80%);
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.leaflet-marker-icon {
    cursor: default !important;
}

.leaflet-control-attribution a {
    display: inline-block;
}

// notification
.ant-notification-notice-icon-info {
    color: @blue2 !important;
}

// progress
.ant-progress-bg {
    background-color: @blue2 !important;
}

// --- Old Styles ---

// // root
// #root {
//     height: 100%;
//     width: 100%;
// }

// // lLink
// a:active {
//     color: unset;
// }

// // margin-bottom
// p,
// ul {
//     margin-bottom: unset;
// }

// layout
// .ant-layout {
//     min-height: 100%;
// }

// .ant-layout-content {
//     display: flex;
//     flex-direction: column;
// }

// .ant-card-actions > li {
//     margin-top: 0 !important;

//     span,
//     span:hover {
//         cursor: default !important;
//         color: unset !important;
//         -webkit-transition: none;
//         transition: none !important;
//     }
// }

// // datepicker

// .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
//     background: @blue2 !important;
//     color: @white1 !important;
//     font-weight: 600 !important;
//     border: none !important;
// }

// .ant-picker-focused {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
//     -webkit-box-shadow: none !important;
// }

// input
// .ant-input-number:focus {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// .ant-input-number-focused {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// .ant-input:focus {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
//}

// select
// .ant-select-selection {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// .ant-select-focused .ant-select-selector,
// .ant-select-selector:focus,
// .ant-select-selector:active,
// .ant-select-open .ant-select-selector {
//     border-color: @grey1 !important;
//     box-shadow: none !important;
// }

// menu
// .ant-menu-title-content {
//     color: @grey3;
// }

// .ant-menu {
//     border: 0 !important;
//     background: white !important;
// }

// .ant-menu-submenu-arrow {
//     color: @grey3 !important;
// }

// .ant-menu-dark .ant-menu-inline.ant-menu-sub {
//     box-shadow: none !important;
//     -webkit-box-shadow: none !important;
//     background: @blue2 !important;
// }

// .ant-menu-horizontal {
//     .ant-menu-item-selected {
//         text-shadow: 0 0 0.25px !important;
//     }
//     padding: 12px 6px;
// }

// .ant-menu-horizontal > .ant-menu-item::after,
// .ant-menu-horizontal > .ant-menu-submenu::after {
//     transition: none !important;
// }

// .ant-menu-horizontal > .ant-menu-item-active:not(.ant-menu-item-selected):after {
//     border-bottom: none !important;
// }

// ant-menu-light.ant-menu-root.ant-menu-inline .ant-menu-light.ant-menu-root.ant-menu-vertical {
//     border-inline-end: 0 !important;
// }

// // table
// .ant-table-summary {
//     display: table-header-group;
// }

// .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.active {
//     color: @grey3 !important;
// }

// .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.active {
//     color: @grey3!important;
// }

// .ant-table .ant-table-wrapper:only-child .ant-table-has-fix-left,
// .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
//     margin: -8px -8px -8px 40px !important;
// }

// .ant-table .ant-table-expanded-row {
//     &.reset-fix {
//         & > .ant-table-cell > .ant-table-expanded-row-fixed {
//             width: auto !important;
//             margin: -16px -16px !important;
//         }
//     }
// }

// .ant-table .ant-table-cell.ant-table-cell-border-left {
//     border-left: 3px solid #f0f0f0;
// }

// // transfer
// .ant-transfer-list {
//     width: calc(50% - 20px) !important;
//     height: 350px !important;
// }

// // pagination
// .ant-pagination-item:hover {
//     border-color: @grey1 !important;
// }

// .ant-pagination-item-active,
// .ant-pagination-item-active:focus {
//     background: @grey1 !important;
//     border-color: @grey1 !important;
// }

// .ant-pagination-item:hover a,
// .ant-pagination-item:focus a {
//     color: @black1 !important;
// }

// .ant-pagination-item-active a,
// .ant-pagination-item-active:focus a,
// .ant-pagination-item-active:hover a {
//     color: #505050 !important;
// }

// .ant-pagination-item-link {
//     border-color: @grey1 !important;
//     color: @grey3 !important;
//     color: @white1;
// }

// // checkbox

// .ant-checkbox {
//     border: solid 1px @white1;
//     border-radius: 1px;
// }

// // button

// .ant-btn {
//     border-color: @grey1;
//     color: @black1;
//     font-weight: 600;
// }

// .ant-btn:hover {
//     border-color: @grey1;
//     background: @white2;
//     color: @black1;
// }

// .ant-btn:focus {
//     border-color: @grey1;
//     background: @white2;
//     color: @black1;
// }

// .ant-btn.ant-btn-danger {
//     background: @red2;
//     color: @white1;
//     border-color: @red2;
// }

// .ant-btn.ant-btn-primary {
//     background: @blue2;
//     color: @white1;
//     background: @blue2;
// }

// [ant-click-animating-without-extra-node]:after {
//     animation: 0s !important;
// }

// // progress

// .ant-progress-status-success .ant-progress-text {
//     color: @grey2 !important;
// }

// // form
// .has-error * {
//     border-color: @grey1 !important;
// }

// .ant-form-item-required::before {
//     display: none !important;
// }

// // tag
// a .ant-tag:hover {
//     background-color: #f0f3ff;
//     cursor: pointer;
// }

// // selector
// .ant-select-selector {
//     height: 100%;
// }

// // collapse
// .ant-collapse-content-box {
//     padding: 30px !important;
// }

// // avatar
// .ant-avatar-string {
//     opacity: 1 !important;
//     transform: scale(1) translateX(-50%) !important;
// }

// // leaflet
// .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
//     background: transparent !important;
//     border: none !important;
//     margin-left: -18px !important;
//     margin-top: 0 !important;
// }

// // breadcrumb
// .ant-breadcrumb {
//     color: red !important;
//     font-weight: 600;
// }

// .ant-breadcrumb-link {
//     color: red !important;
// }

// // tab
// .ant-tabs > .ant-tabs-nav {
//     background: #fff;
//     padding: 12px 24px;
//     // margin: 0 0 1px 0;
//     border-radius: 4px;

//     .ant-tabs-nav {
//         margin: 0 0 4px 0;
//     }
// }

// // statistic
// .ant-statistic-content-prefix {
//     color: @blue2;
//     margin-top: 4px;
// }

// // skeleton
// .ant-skeleton-content .ant-skeleton-title {
//     margin: unset;
// }

// // radio
// .ant-radio-button-wrapper:focus-within {
//     box-shadow: none !important;
// }

// // leaflet
// .leaflet-tile-pane {
//     -webkit-filter: grayscale(80%);
//     filter: grayscale(80%);
// }

// .leaflet-div-icon {
//     background: transparent;
//     border: none;
// }

// .leaflet-marker-icon {
//     cursor: default !important;
// }

// .leaflet-control-attribution a {
//     display: inline-block;
// }

// transfer component
// .transfer-component {
//     & li.ant-transfer-list-content-item {
//         min-height: 28px;
//         max-height: 28px;
//         padding: 0 0 0 17px;
//     }

//     .ant-transfer-list-content-item-text {
//         padding-left: 5px;
//         padding-top: 3px;
//         padding-bottom: 3px;
//         margin-top: 3px;
//     }

//     & .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
//         background-color: unset;

//         span.ant-transfer-list-content-item-text {
//             background-color: @white3;
//             border-radius: 4px;
//             padding-left: 5px;
//         }
//     }

//     li.ant-transfer-list-content-item.ant-transfer-list-content-item-checked {
//         background-color: unset;
//     }
// }

// .ant-tooltip {
//     .ant-tooltip-inner {
//         text-align: center;
//     }
// }

@transparent: rgba(255, 255, 255, 0);@aliceblue: #f0f3ff;@ceil: #93a2dd;@green1: #90be6d;@green2: #337f4c;@green3: #337f4c;@yellow1: #f9c74f;@yellow2: #f8961e;@yellow3: #f3722c;@red1: #f94144;@red2: #d00000;@red3: #9d0208;@grey1: #d9d9d9;@grey2: #a1a1a1;@grey3: #707070;@blue1: #6d8ea0;@blue2: #155292;@white1: #ffffff;@white2: #f8f9fa;@white3: #f5f5f5;@black1: #505050;@black2: #393939;@black3: #000000;
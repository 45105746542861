@transparent: rgba(255, 255, 255, 0);
@aliceblue: #f0f3ff;
@ceil: #93a2dd;

@green1: #90be6d;
@green2: #337f4c;
@green3: #337f4c;

@yellow1: #f9c74f;
@yellow2: #f8961e;
@yellow3: #f3722c;

@red1: #f94144;
@red2: #d00000;
@red3: #9d0208;

@grey1: #d9d9d9;
@grey2: #a1a1a1;
@grey3: #707070;

@blue1: #6d8ea0;
@blue2: #155292;

@white1: #ffffff;
@white2: #f8f9fa;

@black1: #505050;
@black2: #393939;
@black3: #000000;
